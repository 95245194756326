import { useState } from 'react';

var addressSuggested = {};
addressSuggested["Kusama"]= "GU77syTfPXdzKCuqASura9ZKgxmD2rEDhj89c9TGL6vc9Qw";
addressSuggested["Polkadot"] = "16CxXYoq9joofWE13zWuWBfd4tx3kQhXJECLLkhMLgWjYyHG";

// Form to enter delegation address. Can select also our suggested address.
// Stores result in extrinsic
// DONE
function AccountForm({ extrinsic, setExtrinsic }) {
  const [useMathCrypto, setUseMathCrypto] = useState(false);

  function handleSelectMCChange(e) {
    setUseMathCrypto(e.target.checked)
    let act = e.target.checked ? addressSuggested[extrinsic.chain] : ''
    setExtrinsic({...extrinsic, account: act, selectMathCrypto: e.target.checked });    
  }

  return (
    <form>
      <label htmlFor="account"><b>Account</b> to delegate to:</label>       
      <input 
        type="text" id="account"
        value={extrinsic.account} 
        placeholder="XYZ...abc" size="55"
        onChange={e => setExtrinsic({...extrinsic, account: e.target.value })} 
        disabled={useMathCrypto ? true : false} />      
      
      <input 
        type="checkbox" id="checkbox"
        checked={useMathCrypto}
        onChange={handleSelectMCChange} />        
      <label htmlFor="checkbox">Math Crypto ❤ {useMathCrypto ? "Thank you! 👏" : ""} </label>
    </form>
  );
}

// Form to enter balance.
// Stores result in extrinsic
// DONE
function BalanceForm({ extrinsic, setExtrinsic }) {
  return (
    <form>
      <label htmlFor="balance"><b>Balance</b> in {extrinsic.chain=="Polkadot" ? "DOT" : "KSM"} (only . and no , or '):</label> 
      <input 
        type="text" id="balance" 
        value={extrinsic.balance} 
        placeholder="12.34"
        onChange={e => setExtrinsic({...extrinsic, balance: e.target.value })}  />            
    </form>
  );
}

// Radio buttons to choose chain
// Stores result in extrinsic
function ChainRadio({ extrinsic, setExtrinsic }) {
  const [chain, setChain] = useState("Kusama")
  
  const onOptionChange = e => {
    setChain(e.target.value)
    let act = extrinsic.selectMathCrypto ? addressSuggested[e.target.value] : extrinsic.account
    setExtrinsic({...extrinsic, chain: e.target.value, account: act });    
  }

  return (    
      <form>
        <label><b>Chain</b>:</label>
        
        <input type="radio" name="chain" id="kusama" value="Kusama"
               checked={chain === "Kusama"} onChange={onOptionChange}/>
        <label htmlFor="kusama">Kusama</label>    

        <input type="radio" name="chain" id="polkadot" value="Polkadot" 
               checked={chain === "Polkadot"} onChange={onOptionChange}/>
        <label htmlFor="polkadot">Polkadot</label>             
      </form>      
  );
}


// Radio buttons to choose conviction
// Stores result in extrinsic
function ConvictionRadio({ extrinsic, setExtrinsic }) {
  const [conviction, setConviction] = useState("None")
  
  const onOptionChange = e => {
    setConviction(e.target.value)
    setExtrinsic({...extrinsic, conviction: e.target.value });    
  }

  return (    
      <form>
        <label><b>Conviction</b>:</label>
        
        <input type="radio" name="conviction" id="none" value="None"
               checked={conviction === "None"} onChange={onOptionChange} />
        <label htmlFor="none">None (0 days)</label>    

        <input type="radio" name="conviction" id="locked1x" value="Locked1x" 
               checked={conviction === "Locked1x"} onChange={onOptionChange} />
        <label htmlFor="locked1x">Locked1x (8 days)</label>

        <input type="radio" name="conviction" id="locked2x" value="Locked2x" 
               checked={conviction === "Locked2x"} onChange={onOptionChange} />
        <label htmlFor="locked2x">Locked2x (16 days)</label> 
        
        <input type="radio" name="conviction" id="locked3x" value="Locked3x" 
               checked={conviction === "Locked3x"} onChange={onOptionChange} />
        <label htmlFor="locked3x">Locked3x (32 days)</label>
        
        <input type="radio" name="conviction" id="locked4x" value="Locked4x" 
               checked={conviction === "Locked4x"} onChange={onOptionChange} />
        <label htmlFor="locked4x">Locked4x (64 days)</label>

        <input type="radio" name="conviction" id="locked5x" value="Locked5x" 
               checked={conviction === "Locked5x"} onChange={onOptionChange} />
        <label htmlFor="locked5x">Locked5x (128 days)</label>

        <input type="radio" name="conviction" id="locked6x" value="Locked6x" 
               checked={conviction === "Locked6x"} onChange={onOptionChange} />
        <label htmlFor="locked6x">Locked6x (256 days)</label>        
      </form>      
  );
}

// Check buttons to choose tracks of referenda (the referenda itself will be chosen accordingly)
// Stores result in extrinsic
function TrackCheck({ extrinsic, setExtrinsic }) {  
  const [allRefs, setAllRefs] = useState("allRefs")

  function onReferendaChange(id, e) {    
    const nextReferenda = extrinsic.referenda.map(r => {      
      if (r.id==id) {
        return {id: r.id, name: r.name, checked: e.target.checked}        
      } else {        
        return r
      }      
    });    
    setExtrinsic({...extrinsic, referenda: nextReferenda });
  }
  function onReferendaChange(id, e) {    
    const nextReferenda = extrinsic.referenda.map(r => {      
      if (r.id==id) {
        return {id: r.id, name: r.name, checked: e.target.checked}        
      } else {        
        return r
      }      
    });    
    setExtrinsic({...extrinsic, referenda: nextReferenda });
  }
  function resetAllReferendaToTrue(e) {
    const nextReferenda = extrinsic.referenda.map(r => {            
      return {id: r.id, name: r.name, checked: true}              
    });    
    setExtrinsic({...extrinsic, referenda: nextReferenda });
    setAllRefs(e.target.value);
  }
  
  return (    
      <form>
        <label>OpenGov <b>referenda tracks</b>:</label>        

        <input type="radio" name="allRefs" id="allRefs" value="allRefs" 
               checked={allRefs === "allRefs"} onChange={resetAllReferendaToTrue} /> 
        <label htmlFor="allRefs">All</label> 

        <input type="radio" name="allRefs" id="selectRefs" value="selectRefs" 
               checked={allRefs === "selectRefs"} onChange={e => setAllRefs(e.target.value)} />
        <label htmlFor="selectRefs">Only selected</label> 
                
        {extrinsic.referenda.map(referendum => {
        return (
          <>
            <input hidden={allRefs == "allRefs"} key={"check"+referendum.id}
              type="checkbox" id={"track"+referendum.id} checked={referendum.checked}
              onChange={e => onReferendaChange(referendum.id, e)} />        
            <label hidden={allRefs == "allRefs"} htmlFor={"track"+referendum.id}>{referendum.id} ({referendum.name})</label>
          </>
        );
        })}
              
      </form>           
  );
}



// Check buttons to choose remove vote / delegation
// Stores result in extrinsic
function RemoveCheck({ extrinsic, setExtrinsic }) {
  const [unvote, setUnvote] = useState(extrinsic.unvote)
  const [undelegate, setUndelegate] = useState(extrinsic.undelegate)
  
  const onUnvoteChange = e => {
    setUnvote(e.target.checked)
    setExtrinsic({...extrinsic, unvote: e.target.checked });    
  }

  const onUndelegateChange = e => {
    setUndelegate(e.target.checked)
    setExtrinsic({...extrinsic, undelegate: e.target.checked });    
  }

  return (    
      <form>
        <label><b>Remove</b> all current OpenGov:</label>        

        <input 
          type="checkbox" id="undelegate" checked={undelegate}
          onChange={onUndelegateChange} />        
        <label htmlFor="undelegate">delegations</label>                

        <input 
          type="checkbox" id="unvote" checked={unvote} disabled="true"
          onChange={onUnvoteChange} />        
        <label htmlFor="unvote"><s>votes (according to specified referenda tracks)</s> (Disabled for now...) </label>

      </form>      
  );
}

// Summary of extrinsic. More for logging.
// TODO: all tracks or specified (maybe with except XX)
// function SummaryField({ extrinsic }) {  
//   let ref_track_msg = "all the referenda tracks" // make pretty: all, only X, Y, all except X, Y
//   if (extrinsic.undelegate && extrinsic.unvote) {
//     return (
//       <div>     
//         <ul>           
//           <li><b>Remove all delegations</b> (OpenGov) for {ref_track_msg}.</li>
//           <li><b>Remove all votes</b> (OpenGov) that belong to {ref_track_msg}.</li>
//           <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> KSM with <b>{extrinsic.conviction}</b> conviction for <b>{ref_track_msg}</b></li>
//           </ul>
//       </div> );
//   } else if (extrinsic.unvote) {
//     return (
//       <div>              
//         <ul> 
//           <li><b>Do not remove any delegations</b> (OpenGov).</li>
//           <li><b>Remove all votes</b> (OpenGov) that belong to {ref_track_msg}.</li>          
//           <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> KSM with <b>{extrinsic.conviction}</b> conviction for <b>{ref_track_msg}</b></li>
//           </ul>
//       </div> );    
//   } else if (extrinsic.undelegate) {
//     return (
//       <div>              
//         <ul> 
//           <li><b>Remove all delegations</b> (OpenGov) for {ref_track_msg}.</li> 
//           <li><b>Do not remove any votes</b> (OpenGov).</li>
//           <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> KSM with <b>{extrinsic.conviction}</b> conviction for <b>{ref_track_msg}</b></li>
//           </ul>
//       </div> );   
//   } else {
//     return (
//       <div>              
//         <ul> 
//           <li><b>Do not remove any delegations</b> (OpenGov).</li> 
//           <li><b>Do not remove any votes</b> (OpenGov).</li>
//           <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> KSM with <b>{extrinsic.conviction}</b> conviction for <b>{ref_track_msg}</b></li>
//           </ul>
//       </div> );   
//   }                     
// }

function SummaryField({ extrinsic }) {  
  let tracks = extract_referenda_ids(extrinsic.referenda)  
  let all_tracks = extract_referenda_ids(all_referenda)  
  
  let tracks_not_selected = all_tracks.filter(x => !tracks.includes(x));
  let ref_track_msg = '' 
  if (tracks.length == all_referenda.length) {
    ref_track_msg = "all the referenda tracks"
  } else if (tracks.length == 0) {
    ref_track_msg = "none of the referenda tracks"
  } else if (2*tracks.length >= all_referenda.length) {
    ref_track_msg = "all the referenda tracks except " + tracks_not_selected.join(',');
  } else {
    ref_track_msg = "only the referenda tracks " + tracks.join(',');
  }
  
  if (extrinsic.undelegate && extrinsic.unvote) {
    return (
      <div>     
        For <b>{ref_track_msg}</b> and the votes that belong to these tracks:                 
        <ul>           
          <li><b>Remove all delegations</b> (OpenGov) and <b>remove all votes</b> (OpenGov).</li>
          <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> {extrinsic.chain=="Polkadot" ? "DOT" : "KSM"} with <b>{extrinsic.conviction}</b> conviction.</li>
          </ul>
      </div> );
  } else if (extrinsic.unvote) {
    return (
      <div>              
        For <b>{ref_track_msg}</b> and the votes that belong to these tracks:        
        <ul> 
          <li><b>Do not remove any delegations</b> (OpenGov) but <b>remove all votes</b> (OpenGov).</li>          
          <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> {extrinsic.chain=="Polkadot" ? "DOT" : "KSM"} with <b>{extrinsic.conviction}</b> conviction.</li>
          </ul>
      </div> );    
  } else if (extrinsic.undelegate) {
    return (
      <div>
        For <b>{ref_track_msg}</b> and the votes that belong to these tracks:                      
        <ul> 
          <li><b>Remove all delegations</b> (OpenGov) but <b>do not remove any votes</b> (OpenGov).</li>
          <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> {extrinsic.chain=="Polkadot" ? "DOT" : "KSM"} with <b>{extrinsic.conviction}</b> conviction.</li>
          </ul>
      </div> );   
  } else {
    return (
      <div>
        For <b>{ref_track_msg}</b> and the votes that belong to these tracks:                      
        <ul> 
          <li><b>Do not remove any delegations</b> (OpenGov) and <b>do not remove any votes</b> (OpenGov).</li>
          <li>Delegate to account <b>{extrinsic.account=='' ? "(undefined)" : extrinsic.account}</b> the balance of <b>{extrinsic.balance==''? "(undefined)" : extrinsic.balance}</b> {extrinsic.chain=="Polkadot" ? "DOT" : "KSM"} with <b>{extrinsic.conviction}</b> conviction.</li>
          </ul>
      </div> );   
  }                     
}

function extract_referenda_ids(referenda_array) {  
  const tracks = []
  referenda_array.forEach((referendum) => {
    if (referendum.checked) {
      tracks.push(referendum.id);
    }
  });    
  return tracks;
}


// Submit to server and display result
// DONE
function SubmitServerButton({ extrinsic, serverMessageDelegate, setServerMessageDelegate, serverMessageRemove, setServerMessageRemove }) {
  const [submitHash, setSubmitHash] = useState(false);  

  let handleSubmitHash = async (e) => {
    let tracks = extract_referenda_ids(extrinsic.referenda)    
    setSubmitHash(true)
    setServerMessageRemove({status: '', info: '', hash: '' });  
    setServerMessageDelegate({status: '', info: '', hash: '' });  
    e.preventDefault();
    let body = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address: extrinsic.account,
        balance: extrinsic.balance,
        conviction: extrinsic.conviction,
        unvote: extrinsic.unvote,
        undelegate: extrinsic.undelegate,
        chain:  extrinsic.chain,        
        tracks: tracks 
      })
    };
    // Remove hash
    if (extrinsic.unvote || extrinsic.undelegate) {
      try {
        //let res = await fetch("https://httpbin.org/post", {
        let res = await fetch("https://rest-server.math-crypto.com/unvoteundelegate", body);      
        //let res = await fetch("http://127.0.0.1:5000/unvoteundelegate", body);      
        let resJson = await res.json();                  
        if (res.status === 200) {        
          setServerMessageRemove({status: resJson.status, info: resJson.info, hash: resJson.hash });                      // resJson.json.hash         
        } else {
          setServerMessageRemove({status: res.status, info: "Some error occurred after fetch", hash: ''})              
        } 
      } catch (err) {
        setServerMessageRemove({status: '', info: "Some error occurred during fetch to /unvoteundelegate", hash: ''})      
        console.log(err);      
      }  
    } else {
      setServerMessageRemove({status: 200, info: "No hash needed.", hash: '(no hash required)'})     
    }
    // Delegate hash
    try {
      //let res = await fetch("https://httpbin.org/post", {
      let res = await fetch("https://rest-server.math-crypto.com/delegate", body);
      //let res = await fetch("http://127.0.0.1:5000/delegate", body);      
      let resJson = await res.json();                  
      if (res.status === 200) {        
        setServerMessageDelegate({status: resJson.status, info: resJson.info, hash: resJson.hash });                      // resJson.json.hash         
      } else {
        setServerMessageDelegate({status: res.status, info: "Some error occurred after fetch", hash: ''})              
      } 
    } catch (err) {
      setServerMessageDelegate({status: '', info: "Some error occurred during fetch to /delegate", hash: ''})      
      console.log(err);      
    }  
    setSubmitHash(false)  
  };

  return (
    <div>       
       <ul>
        <li>
          <button onClick={handleSubmitHash} disabled={submitHash ? true : false}>
              {submitHash ? "Submitted. Please wait (up to 1 minute)..." : "Generate hash"} 
          </button> 
        </li>
        <li>
        <p> Send first the <b>remove extrinsic</b> (for removing old votes and/or delegations) </p>
        {/* <ul>
        <li> by either following this <a href={serverMessageRemove.url}> link </a> to polkadot.JS; </li>        
        <li> or manually using the hash below: </li>
        </ul> */}
          <textarea readOnly id="serverMessageRemove" name="serverMessageRemove" rows="7" cols="60" 
          value={(serverMessageRemove.status==200) ? serverMessageRemove.hash : serverMessageRemove.info } />                      
        <p>
          <button onClick={() =>  navigator.clipboard.writeText(serverMessageRemove.hash)} disabled={(serverMessageRemove.status==200) ? false : true}>Copy first hash to clipboard</button>
        </p>    
        <p> Send next the <b>delegate extrinsic</b> (for issuing the new delegations): </p>
          <textarea readOnly id="serverMessageDelegate" name="serverMessageDelegate" rows="7" cols="60" 
          value={(serverMessageDelegate.status==200) ? serverMessageDelegate.hash : serverMessageDelegate.info } />                      
        <p>
          <button onClick={() =>  navigator.clipboard.writeText(serverMessageDelegate.hash)} disabled={(serverMessageDelegate.status==200) ? false : true}>Copy second hash to clipboard</button>
        </p>   
        </li>  
      </ul>                
    </div>   )  
  
}

const all_referenda = [
  {id: 0, name: 'Root', checked: true},
  {id: 1, name: 'Whitelisted Caller', checked: true},

  {id: 10, name: 'Staking Admin', checked: true},
  {id: 11, name: 'Treasurer', checked: true},
  {id: 12, name: 'Lease Admin', checked: true},
  {id: 13, name: 'Fellowship Admin', checked: true},
  {id: 14, name: 'General Admin', checked: true},
  {id: 15, name: 'Auction Admin', checked: true},

  {id: 20, name: 'Referendum Canceller', checked: true},
  {id: 21, name: 'Referendum Killer', checked: true},
  
  {id: 30, name: 'Small Tipper', checked: true},
  {id: 31, name: 'Big Tipper', checked: true},
  {id: 32, name: 'Small Spender', checked: true},
  {id: 33, name: 'Medium Spender', checked: true},
  {id: 34, name: 'Big Spender', checked: true}
];

// Global component
function DelegationHashEncoder() {  
  // Hard coded -- changed only after runtime upgrade
  
  const [extrinsic, setExtrinsic] = useState({
    account: '',
    balance: '',
    conviction: 'None',
    undelegate: false,
    unvote: false,
    referenda: all_referenda,
    chain: "Kusama",
    selectMathCrypto: false
  });
  const [serverMessageDelegate, setServerMessageDelegate] = useState({
    status: '',
    info: '',
    hash: '',
    url: ''
  });
  const [serverMessageRemove, setServerMessageRemove] = useState({
    status: '',
    info: '',
    hash: '',
    votes: '', // Add votes that are included in the referenda tracks    
    url: ''
  });

  return (
    <div>
      <h1>Delegation hash generator for OpenGov on Polkadot and Kusama </h1>    

      <h3>News</h3>

      <p>🎉 🎉 From 15 June 2023, OpenGov is live on Polkadot! 🎉 🎉 </p> 
      
      <p>🛠️ Our batch delegator should work on Polkadot but is still WIP. Let us know any bugs! 🛠️ </p>

      <h3>How it works</h3>

      <p>Generate a batch call to delegate your current and future votes for <b>a custom list of referenda</b> to an account specified below. This account can be any account, yours or not.</p>
            
      <p>
        Your account that will delegate its votes has to <b>send the extrinsic</b>. <i>This requires explicit approval by the sending account which is not done on this page! </i> See <a href="https://www.math-crypto.com/opengov/batch-delegate/">here for instructions</a> on how to submit it on <a href="https://polkadot.js.org/apps/#/extrinsics">Polkadot.js</a>.
      </p>     
      
      <p>You can use a <b>Ledger account</b> for sending the extrinsic <i>but you need to set up a <b>Governance</b> or Any proxy for it.</i></p>

      <p>
        If you have <b>existing OpenGov votes or OpenGov delegations</b> for the account that will send the delegate extrinsic, you have to <b>first send the remove extrinsic</b>. <i>Sending the remove extrinsic is cheap and can be done even without prior votes or delegations. So send it anyway unless you are sure.</i>
      </p>

      <p>Brought to you by <a href="https://www.math-crypto.com">Math Crypto</a>. Feedback welcome on <a href="https://matrix.to/#/@mathcrypto:matrix.org">@mathcrypto:matrix.org</a> or via a <a href="https://github.com/MathCryptoDoc/math-crypto-webpage/issues">GitHub issue</a>.</p>       
      
      <h3>(1) Specify the delegation</h3>

      <p>⚠️ ⚠️ Since early June 2023, there is a <a href="https://github.com/paritytech/substrate/issues/14235">bug</a> in Substrate that makes submitting longer batch calls (like we do here) impossible on <b>Kusama (but Polkadot seems fine)</b>. <b>The hash below will therefore not work if you apply it to all tracks!</b> Until a fix is out, the only solution now is choosing a <b>maximum of 9 tracks</b> at once in each batch call. For delegating to all tracks, you therefore need to send two different batch calls. ⚠️ ⚠️</p>

      <h4>Required fields:</h4>
      <ul>
        <li><ChainRadio extrinsic={extrinsic} setExtrinsic={setExtrinsic} /></li>
        <li><AccountForm extrinsic={extrinsic} setExtrinsic={setExtrinsic} /></li>    
        <li><BalanceForm extrinsic={extrinsic} setExtrinsic={setExtrinsic} /></li>
      </ul>
      <h4>Optional fields (defaults will work <i>unless the sending account voted with conviction on finished referenda</i>):</h4>
      <ul>
        <li><ConvictionRadio extrinsic={extrinsic} setExtrinsic={setExtrinsic} /></li>
        <li><TrackCheck extrinsic={extrinsic} setExtrinsic={setExtrinsic} /></li> 
        <li><RemoveCheck extrinsic={extrinsic} setExtrinsic={setExtrinsic} /></li> 
      </ul>      

      <h3>(2) Summary of the extrinsics to generate</h3>
      <SummaryField extrinsic={extrinsic} />      

      <h3>(3) Hashes of the extrinsics to copy paste in the decode field</h3>
      <SubmitServerButton extrinsic={extrinsic}
        serverMessageDelegate={serverMessageDelegate} setServerMessageDelegate={setServerMessageDelegate}
        serverMessageRemove={serverMessageRemove} setServerMessageRemove={setServerMessageRemove} />
    </div>
  );
}

export default function App() {
  return <DelegationHashEncoder />;
}